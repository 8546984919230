import React, { useEffect } from "react";
import { Link, graphql } from "gatsby";
import Layout from "../../components/layout.en";
import SEO from "../../components/seo";
import { WOW } from "wowjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BackgroundImage from "gatsby-background-image";

const ServicesPageEN = ({ data }) => {
    useEffect(() => {
        const wow = new WOW({
            boxClass: "wow",
            animateClass: "animate",
            offset: 100,
            mobile: false,
            live: true,
        });

        wow.init();
    });

    return (
        <Layout>
            <SEO title="Services" description="Get to know what are our core expertise industries" lang="en" />

            <BackgroundImage backgroundColor={`#040e18`} fluid={data.heroImage.childImageSharp.fluid} className="brook-breadcaump-area ptb--270 pt_md--190 pb_md--100 pt_sm--190 pb_sm--100 breadcaump-title-bar breadcaump-title-white" data-black-overlay="8" Tag="div">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcaump-inner text-center">
                                <h6 className="heading heading-h6 text-white">SERVICES</h6>
                                <div className="bkseparator--30"></div>
                                <h1 className="heading heading-h1 text-white line-height-1 font-80">Our Work</h1>
                                <div className="bkseparator--30"></div>
                                <h5 className="heading heading-h5 text-white line-height-1-95">
                                    With our 7 years of experience in making custom web development <br />
                                    we are proud to be insightful on the following industries
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </BackgroundImage>

            <main className="page-content">
                <div className="brook-icon-boxes-area basic-thine-line pb--100 pb_md--80 pb_sm--80">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="row bg_color--1 pt--100 space_dec--110 poss_relative basic-thick-line-theme-4">
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 wow move-up">
                                        <div className="icon-box text-center no-border">
                                            <div className="inner">
                                                <div className="icon">
                                                    <FontAwesomeIcon icon={["fal", "prescription-bottle"]} />
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading heading-h5">Pharma Industry</h5>
                                                    <p className="bk_pra">With a 5 year experience on developing and supporting pharmaceutical industries with reporting, marketing and sales, we are now focused in Data Driven AI technologies.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--70 wow move-up">
                                        <div className="icon-box text-center no-border">
                                            <div className="inner">
                                                <div className="icon">
                                                    <FontAwesomeIcon icon={["fal", "tshirt"]} />
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading heading-h5">Fashion</h5>
                                                    <p className="bk_pra">
                                                        Our experience in fashion extends to different areas. We've created ecommerce websites, developed social media strategies and photographed campaigns. Our knowledge about this industry allow us to advise our clients and think from a customer
                                                        perspective (both B2C or B2B).
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--70 mt_sm--70 wow move-up">
                                        <div className="icon-box text-center no-border">
                                            <div className="inner">
                                                <div className="icon">
                                                    <FontAwesomeIcon icon={["fal", "vial"]} />
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading heading-h5">Cosmetics</h5>
                                                    <p className="bk_pra">We've been working with cosmetics brands since started to develop web solutions (e-commerce, CRM, content management, B2B platforms) and capture product photographs, displayed on social media or the brands website. </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--70 wow move-up">
                                        <div className="icon-box text-center no-border">
                                            <div className="inner">
                                                <div className="icon">
                                                    <FontAwesomeIcon icon={["fal", "shopping-cart"]} />
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading heading-h5">Retail & Loyalty</h5>
                                                    <p className="bk_pra">
                                                        Ecommerce websites and loyalty programs are part of our expertise. Every project is custom made according to our customer needs and business characteristics. We've developed, Whitebrim, a in house software that allows us to create every
                                                        solution with maximum efficiency.{" "}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--70 wow move-up">
                                        <div className="icon-box text-center no-border">
                                            <div className="inner">
                                                <div className="icon">
                                                    <FontAwesomeIcon icon={["fal", "chart-line"]} />
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading heading-h5">CRM / ERP</h5>
                                                    <p className="bk_pra">We are specialized in creating custom made CRM and ERP that allow our clients to effectively manage customer data, improve and integrate internal core processes in order to inovate and scale their businesses</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--70 wow move-up">
                                        <div className="icon-box text-center no-border">
                                            <div className="inner">
                                                <div className="icon">
                                                    <FontAwesomeIcon icon={["fal", "ad"]} />
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading heading-h5">Advertisement</h5>
                                                    <p className="bk_pra">We developed display ads, banners, mail marketing, landing pages, e-detailing and brand activation, alongside other agencies to grow our clients market and brand awareness.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="brook-gradation-area ptb--100 ptb-md--80 ptb-sm--60 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="brook-section-title text-center">
                                    <div className="bkseparator--35"></div>
                                    <h3 className="heading heading-h3">Work process</h3>
                                </div>
                                <div className="bk-gradation mt--60 mt_sm--5">
                                    <div className="item-grid custom-color--2 mt--40 move-up-x wow" data-wow-delay=".13s">
                                        <div className="line"></div>
                                        <div className="dot-wrap">
                                            <div className="dot">
                                                <div className="count">1</div>
                                            </div>
                                        </div>
                                        <div className="content">
                                            <h5 className="heading heading-h5">Brainstorming</h5>
                                            <p className="bk_pra">Carefuly study the challenge proposed and prepare the creative concepts.</p>
                                        </div>
                                    </div>
                                    <div className="item-grid custom-color--3 mt--40 move-up-x wow" data-wow-delay=".19s">
                                        <div className="line"></div>
                                        <div className="dot-wrap">
                                            <div className="dot">
                                                <div className="count">2</div>
                                            </div>
                                        </div>
                                        <div className="content">
                                            <h5 className="heading heading-h5">Development</h5>
                                            <p className="bk_pra">With planned cycles of development we bring the vision to reality</p>
                                        </div>
                                    </div>
                                    <div className="item-grid custom-color--4 mt--40 move-up-x wow" data-wow-delay=".2s">
                                        <div className="line"></div>
                                        <div className="dot-wrap">
                                            <div className="dot">
                                                <div className="count">3</div>
                                            </div>
                                        </div>
                                        <div className="content">
                                            <h5 className="heading heading-h5">Adjusting & Support</h5>
                                            <p className="bk_pra">Planned Releases and long-term supporting</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="brook-call-to-action bg_color--2 ptb--70">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-sm-6 col-12">
                                <div className="call-content text-center text-sm-left">
                                    <h3 className="heading heading-h3 text-white wow move-up">Let's start working together</h3>
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-6 col-12">
                                <div className="call-btn text-center text-sm-right mt_mobile--20 wow move-up">
                                    <Link className="brook-btn bk-btn-white text-theme btn-sd-size btn-rounded" to="/en/contact-us/">
                                        Contact us
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </Layout>
    );
};

export default ServicesPageEN;

export const query1 = graphql`
    query {
        heroImage: file(relativePath: { eq: "unsplash_5.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    base64
                    aspectRatio
                    src
                    srcSet
                    sizes
                    originalImg
                }
            }
        }
    }
`;
